import {
  Chip,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { RouteComponentProps } from 'react-router'
import { authedFetch } from '../../dataProvider/authedFetch'
import { NodeActionProps } from '../../genericData/node-actions/NodeActionType'

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    padding: '16px',
    overflow: 'inherit',
    minHeight: '52px',
    marginBottom: '32px',
  },
  cardTitleDescr: {
    margin: 0,
    marginTop: '12px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  chip: {
    marginLeft: 2,
  },
  util: {
    height: 50,
    marginBottom: 10,
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,

    '& table': {
      width: '100%',
      '& th': {
        fontWeight: 'bold',
      },
      '& tr:nth-of-type(odd)': {
        backgroundColor: '#f5f5f5',
      },
    },
  },
}))

type Props = RouteComponentProps & NodeActionProps

type ServiceState = {
  name: string
  containers: { age: string; version: string }[]
}
type Response = ServiceState[]

export const InfrastructureOverview = (props: Props) => {
  const classes = useStyles()
  const notify = useNotify()

  const [apiState, setAPIState] = useState<Response | null>(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await authedFetch('/api/infrastructure/versions', {})
      const json = await response.json()
      setAPIState([...json].sort((a, b) => a.name.localeCompare(b.name)))
    } catch (error) {
      notify('Error: fetch failed', 'warning')
    }
  }, [])

  useEffect(() => {
    fetchData()
    const i = setInterval(fetchData, 10000)
    return () => {
      clearInterval(i)
    }
  }, [fetchData])

  return (
    <div>
      <Card className={classes.cardTitle}>
        <Typography variant="h5">SMPL Infrastructure Overview</Typography>
        <p className={classes.cardTitleDescr}>
          This page shows running versions of the services within SMPL. They are
          based on the actually deployed versions of Docker images. Mostly the
          naming structure is such that the last part of the version is the
          build number in the CI pipeline.
          <br />
          This page refreshes every 10 seconds.
        </p>
      </Card>

      <Card className={classes.selectCard}>
        {apiState ? (
          <>
            <Table>
              <TableHead>
                <TableCell>Service</TableCell>
                <TableCell>Version</TableCell>
              </TableHead>
              {apiState.map((s) => (
                <TableRow key={s.name}>
                  <TableCell>{s.name}</TableCell>
                  <TableCell>
                    {s.containers.map((v) => (
                      <Chip
                        key={v.version}
                        className={classes.chip}
                        icon={
                          s.containers.length > 1 ? (
                            <span>⚠️</span>
                          ) : (
                            <span>✔️</span>
                          )
                        }
                        label={v.version + ' (' + v.age + ')'}
                      />
                    ))}
                    {s.containers.length > 1 && (
                      <p>
                        Currently deploying a new version or{' '}
                        <b>failing to deploy</b> the most recent version.
                      </p>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </>
        ) : (
          <div style={{ width: 50, height: 50, padding: 20 }}>
            <div>
              <CircularProgress
                size="small"
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
