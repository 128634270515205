import { isArdCustomer, isWhitelabelCustomer } from '../../lib/config'
import { filterNotEmpty } from '../../lib/filterNonEmpty'

const CategoryFlagOptions = [
  { label: 'Big Cover', value: 'big-cover' },

  ...(isArdCustomer || isWhitelabelCustomer
    ? [
        { label: 'Square Image', value: 'square-image' },
        { label: 'Subcategory Slider', value: 'subcategory-slider' },
        {
          label: 'Continue Watching Placeholder',
          value: 'watchlist-placeholder',
        },
        { label: 'Wishlist Placeholder', value: 'wishlist-placeholder' },
      ]
    : []),
].filter(filterNotEmpty)

const WidgetFlagOptions = [{ label: 'Default Widget', value: 'default' }]

const AutomationSortFlagOptions: { label: string; value: string }[] = [
  // INSIGHTS EVENT
  //   { label: '[Sort] Most Watched', value: 'sortMostWatched' },
  { label: '[Sort] Random', value: 'sortRandom' },
  // WATCHLIST
  { label: '[Sort] Most Watchlist', value: 'sortMostWatchlist' },
  // WISHLIST
  { label: '[Sort] Most Wishlist', value: 'sortMostWishlist' },
  // LICENSE
  { label: '[Sort] Latest License', value: 'sortLatestLicence' },
]

const AutomationGenerationFlagOptions: { label: string; value: string }[] = [
  // INSIGHTS EVENT
  { label: '[Gen] Most Watched', value: 'generateMostWatched' },
  {
    label: '[Gen] Most Watched - Movies Only',
    value: 'generateMostWatchedMoviesOnly',
  },
  {
    label: '[Gen] Most Watched - Series Only',
    value: 'generateMostWatchedSeries',
  },
  // for FH-269
  //   {
  //     label: '[Gen] Least Watched',
  //     value: 'generateLeastWatched',
  //   },
  //   {
  //     label: '[Gen] Least Watched - Movies Only',
  //     value: 'generateLeastWatchedMoviesOnly',
  //   },
  //   {
  //     label: '[Gen] Least Watched - Series Only',
  //     value: 'generateLeastWatchedSeriesOnly',
  //   },

  // WATCHLIST
  { label: '[Gen] Most Watchlist', value: 'generateMostWatchlist' },
  {
    label: '[Gen] Most Watchlist - Movies Only',
    value: 'generateMostWatchlistMoviesOnly',
  },
  {
    label: '[Gen] Most Watchlist - Series Only',
    value: 'generateMostWatchlistSeriesOnly',
  },

  // WISHLIST
  { label: '[Gen] Most Wishlist', value: 'generateMostWishlist' },

  // LICENSE
  { label: '[Gen] Latest License', value: 'generateLatestLicence' },
  {
    label: '[Gen] Latest License - Movies Only',
    value: 'generateLatestLicenceMoviesOnly',
  },
  {
    label: '[Gen] Latest License - Series Only',
    value: 'generateLatestLicenceSeriesOnly',
  },
]

export const CategoryFlagsData = {
  options: [
    { label: 'All Devices', value: '*:', flagOptions: CategoryFlagOptions },
    { label: 'SmartTVs', value: 'tv:', flagOptions: CategoryFlagOptions },
    { label: 'Android', value: 'android:', flagOptions: CategoryFlagOptions },
    { label: 'iOS', value: 'ios:', flagOptions: CategoryFlagOptions },
    {
      label: 'Android & iOS',
      value: 'native:',
      flagOptions: CategoryFlagOptions,
    },
    { label: 'Web', value: 'web:', flagOptions: CategoryFlagOptions },
    { label: 'Widgets', value: 'widget:', flagOptions: WidgetFlagOptions },
    // Categories that have this flag will be considered when the automation is running
    // It will sort the movies/series that have this categories depending on the selected flag
    {
      label: 'Auto',
      value: 'automation:',
      flagOptions: [
        ...AutomationSortFlagOptions,
        ...AutomationGenerationFlagOptions,
      ],
    },
  ],
}

export const MovieFlagsData = {
  options: [
    {
      label: 'Adaptives Streaming',
      value: 'adaptives_streaming:',
      flagOptions: [
        { label: 'HD', value: 'HD' },
        { label: 'SD', value: 'SD' },
      ],
    },
    {
      label: 'Exclude API',
      value: 'excludeAPI:',
      flagOptions: [
        { label: 'Android Phone', value: 'android-phone' },
        { label: 'Android Tablet', value: 'android-tablet' },
        { label: 'iPhone', value: 'iphone' },
        { label: 'iPad', value: 'ipad' },
        { label: 'ATV', value: 'atv' },
        { label: 'Fire TV', value: 'firetv' }, // known duplicate
        { label: 'Fire-TV', value: 'fire-tv' }, // known duplicate
        { label: 'Sony TV', value: 'sony-tv' },
        { label: 'LG TV', value: 'tv-lg' },
        { label: 'Technisat TV', value: 'technisat-tv' },
        { label: 'Panasonic', value: 'panasonic' },
        { label: 'SmartTV - Entertain', value: 'smarttv-entertain' },
        { label: 'SmartTV Samsung Tizen', value: 'smarttv-samsung-tizen' },
        { label: 'SmartTV Samsung', value: 'smarttv-samsung' },
        { label: 'SmartTV Alliance', value: 'smarttv-alliance' },
        { label: 'WWW', value: 'www' },
        { label: 'Windows 8', value: 'windows8' },
        { label: 'Windows Phone 8', value: 'windowsphone8' }, // known duplicate
        { label: 'Windows-Phone 8', value: 'windows-phone8' }, // known duplicate
        { label: 'XBOX', value: 'xbox' },
      ],
    },
    {
      label: 'Offline Available',
      value: 'offlineAvailable:',
      flagOptions: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
    },
    {
      label: 'Properties',
      value: 'properties:',
      flagOptions: [
        { label: 'SVOD', value: 'svod' },
        { label: 'AVOD', value: 'avod' },
        { label: 'FAST', value: 'fast' },
        { label: 'Exclusive', value: 'exclusive' },
        { label: 'Plus Exclusive', value: 'plus-exclusive' },
        { label: 'Voucher Exclusive', value: 'voucher-exclusive' },
      ],
    },
    {
      label: 'Language & Accessibility Features',
      value: 'languageAndAccessibilityFeatures:',
      flagOptions: [{ label: 'OMU', value: 'omu' }],
    },
  ],
}
