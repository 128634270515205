/**
 * Booleans datas coming later from database, which
 * tell whether to render a resource or not
 */

import {
  isArdCustomer,
  isFlexgoldCustomer,
  isUniversumCustomer,
} from '../lib/config'

let ResourceDisplaySetting: {
  [key: string]: { [key: string]: boolean }
} = {}
if (isFlexgoldCustomer) {
  ResourceDisplaySetting = {
    customer: {
      User: true,
      Account: true,
      IdentificationDatum: true,
      ReferenceBankAccount: true,
      RegistrationCode: true,
      Campaign: true,
    },
    vault: {
      Vault: true,
      VaultRole: true,
    },
    orders: {
      Order: true,
      OrderSchedule: true,
      Transaction: true,
      HistoricPrice: true,
    },
    messages: {
      NotificationMessage: true,
      NotificationTemplate: true,
      Document: true,
      UserNotificationDevice: true,
      UserNotificationSubscription: true,
    },
    configuration: {
      VaultSurcharge: true,
      CountryInformation: true,
      CurrencyExchangeCost: true,
      GlobalConfiguration: true,
      ScheduledTask: true,
      TermsAndCondition: true,
      Holiday: true,
    },
    taggedUsers: {
      IdentificationDatum: true,
    },
    logs: {
      AuditLog: true,
      AppConfigurationObjectHistory: true,
      DataCache: true,
      ScheduledTaskExecution: true,
      UserTermsAndCondition: true,
      CampaignEvent: true,
    },
    other: {
      IdempotentRequest: true,
      Shortlink: true,
      CoBrandingConfiguration: true,
    },
  }
} else {
  ResourceDisplaySetting = {
    cms: {
      CmsCategory: true,
      CmsClip: true,
      CmsEpisode: true,
      CmsImage: true,
      CmsMovie: true,
      CmsPerson: true,
      CmsSeason: true,
      CmsSery: true,
      CmsFeedObject: isArdCustomer || isUniversumCustomer,
      // CmsTrailer: true,
    },
    'l&c': {
      CmsLicense: true,
      CmsLicensor: true,
      CmsContract: true,
    },
    movieOfTheDay: {
      ModMovie: true,
      PushToken: true,
    },
    transcoding: {
      ImportRequest: true,
      TranscodingJob: true,
    },
    'p&p': {
      PaymentTransaction: true,
      PaymentTransactionLog: true,
      PaymentTransactionContentProduct: true,
      PaymentInvoice: true,
      PaymentExternalUser: true,
      PaymentScheduledCancellation: true,
      Subscription: true,
      SubscriptionTerm: true,
      SubscriptionProduct: true,
      CmsMovieContentProduct: true,
      MerchantConfiguration: true,
    },
    voucher: {
      VoucherCampaign: true,
      VoucherCode: true,
    },
  }
}

export const ResourceIconSetting = {
  cms: 'CMSIcon',
  movieOfTheDay: 'TheatersIcon',
  transcoding: 'TranscodingIcon',
  'l&c': 'GravelsIcon',
  voucher: 'TicketsIcon',
  'p&p': 'PaymentsIcon',
  messages: 'MessageIcon',
  configuration: 'SettingsIcon',
  vault: 'VaultIcon',
  customer: 'CustomerIcon',
  orders: 'OrdersIcon',
  logs: 'LogsIcon',
  other: 'OtherIcon',
  taggedUsers: 'taggedMonitoringUsersIcon',
  termsAndConditions: 'TermsAndCondtionsIcon',
}

export { ResourceDisplaySetting }
