import {
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ClearIcon from '@material-ui/icons/Delete';
import { JSXElementConstructor, ReactElement, useCallback, useState } from "react";
import { Button, showNotification, useDataProvider } from "react-admin";
import { useDropzone } from "react-dropzone";
import { useField, useFormState } from 'react-final-form';
import { connect } from 'react-redux';
import { getToken } from '../../authProvider';
import { headersForApi } from '../../commons/flexgold/constants';
import { FieldTraverser } from "../../dataProvider/introspections/SchemaTraverser";

const useStyles = makeStyles({
  card: {
    display: 'block'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16
  },
  actionZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 16
  },
  container: {
    minHeight: 48,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px #eeeeee dashed',
    backgroundColor: '#fafafa',
    padding: 16
  },
  title: {
    fontSize: 14,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    marginBottom: 16,
    width: '100%',
    maxHeight: 200,
  },
  thumbAndButtonContainer: {
    width: '100%',
    maxHeight: 200,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding: 4,
    boxSizing: 'border-box',
    width: '100%',
    maxHeight: 200,
    position: 'relative',
    top: -27,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    display: 'block',
    width: 'auto',
    maxHeight: 190,
  },
  imgToolbar: { display: 'flex', justifyContent: 'flex-end' },
  imgIconTopCorner: {
    height: '25px',
    width: '25px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '1px 1px 1px 1px',
    zIndex: 1000,
  },
})

export type GetFileUploadUrlResponse =
  | {
      endpoint: string
      method: 'POST' | 'PUT'
      fileMeta: {
        imageUrl: string
      }
    }

const uploadFile = async (uploadUrl: URL, file: File, dimensions: {
  width: number;
  height: number;
}): Promise<{
  file: File
  fileMeta: {
    imageUrl: string
  }
  success: true
  error?: { message: string[] }
} | { success: false, error?: { message: string[] } }> => {
  const clonedUrl = new URL(uploadUrl)
  clonedUrl.searchParams.set('name', file.name)
  clonedUrl.searchParams.set('type', file.type)
  clonedUrl.searchParams.set('size', `${file.size}`)
  clonedUrl.searchParams.set('height', `${dimensions.height}`)
  clonedUrl.searchParams.set('width', `${dimensions.width}`)

  try {
    const response = await fetch(clonedUrl.toString(), {
      method: 'GET',
      headers: {
        ...headersForApi,
        Authorization: `Bearer ${getToken()}`
      },
    })

    if (!response.ok) {
      try {
        return await response.json()
      } catch {
        throw response.status
      }
    }

    const result: GetFileUploadUrlResponse = await response.json()

    if ('endpoint' in result) {
      const { endpoint, method, fileMeta } =
        result
      return uploadToEndpoint({
        file,
        endpoint,
        method,
        fileMeta,
      })
    } else {
      console.error(
        'GET_UPLOAD_URL error: Missing upload URL endpoint in response.'
      )
      return result
    }
  } catch (error: unknown) {
    console.error('GET_UPLOAD_URL error:', error)
    return { success: false }
  }
}

function getImageDimensions(imageSrc: string) {
  return new Promise<{ width: number, height: number }>((resolve) => {const img = new Image();
    img.onload = function() {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function() {
      resolve({ width: 0, height: 0 });
    };

    img.src = imageSrc
  })
}

const uploadToEndpoint = async ({
  file,
  endpoint,
  method = 'POST',
  fileMeta,
}: {
  file: File
  endpoint: string
  method: 'POST' | 'PUT'
  fileMeta: {
    imageUrl: string
  }
}): Promise<{
  file: File
  fileMeta: {
    imageUrl: string
  }
  success: true
} | { success: false}> => {
  if (!file) {
    return { success: false }
  }

  try {
    const response = await fetch(endpoint, {
      method,
      body: file as File,
    })

    if (!response.ok) {
      const errorResponse = await response.text()

      try {
        return JSON.parse(errorResponse)
      } catch {}

      throw errorResponse
    }

    return {
      file: file,
      fileMeta,
      success: true
    }
  } catch (error: unknown) {
    console.error('UPLOAD error: ', error)
    return { success: false }
  }
}

export const deleteFile = async (uploadUrl: URL): Promise<boolean> => {
  try {
    const response = await fetch(uploadUrl.toString(), {
      method: 'DELETE',
      headers: {
        ...headersForApi,
        Authorization: `Bearer ${getToken()}`
      },
    })

    return response.ok
  } catch (error: unknown) {
    console.error('GET_UPLOAD_URL error:', error)
    return false
  }
}

type ImageInputProps = {
  field: FieldTraverser
  label?: string | ReactElement<any, string | JSXElementConstructor<any>>
  className?: string
  uploadUrl: URL
} & {
  showNotification: typeof showNotification
}

export const _SignedUrlFileUploadInput = (props: ImageInputProps) => {
  const {
    field,
    label,
    className: style,
    uploadUrl,
    showNotification: notify,
  } = props
  const {
    name: fieldName,
  } = field
  const classes = useStyles()
  const currentFieldState = useFormState()
  const formField = useField(fieldName)
  const dataProvider = useDataProvider()
  uploadUrl.searchParams.set('id', currentFieldState.values.id)

  const [image, setImage] = useState<File | undefined>()
  const [imagePath, setImagePath] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    onDrop: useCallback(
      async (acceptedFiles) => {
        const image = acceptedFiles[0]

        if (imagePath) {
          URL.revokeObjectURL(imagePath)
        }

        setIsSuccess(false)
        setImage(image)
        setImagePath(URL.createObjectURL(image))
      },
      []
    ),
    multiple: false,
  })

  const uploadImage = async () => {
    setIsLoading(true)

    try {
      const dimensions = await getImageDimensions(imagePath)
      const res = await uploadFile(uploadUrl, image, dimensions)

      if (res.success) {
        formField.input.onChange(res.fileMeta.imageUrl)

        const previousData = await dataProvider.getOne(field.resource.name, {
          id: currentFieldState.values.id,
        })

        await deleteFile(uploadUrl)

        await dataProvider.update(field.resource.name, {
          id: currentFieldState.values.id,
          data: {
            ...previousData.data,
            profileImageUrl: res.fileMeta.imageUrl
          },
          previousData: {
            id: currentFieldState.values.id,
            ...previousData.data
          }
        })

        setImage(undefined)
        setImagePath(undefined)
        setIsSuccess(true)

        notify('Upload successful', 'success')
      } else {
        notify(res?.error?.message?.[0] || 'Upload failed', 'error')
      }
    } catch(err) {
      console.log(err)
      notify('Upload failed', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const _deleteFile = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    try {
      if (imagePath){
        URL.revokeObjectURL(imagePath)
      } else {
        await deleteFile(uploadUrl)
      }

      setIsSuccess(false)
      setImagePath(undefined)
      setImage(undefined)
      formField.input.onChange(null)

      const previousData = await dataProvider.getOne(field.resource.name, {
        id: currentFieldState.values.id,
      })

      if (previousData.data.profileImageUrl != null) {
        await dataProvider.update(field.resource.name, {
          id: currentFieldState.values.id,
          data: {
            ...previousData.data,
            profileImageUrl: null
          },
          previousData: {
            id: currentFieldState.values.id,
            ...previousData.data
          }
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={style} key={fieldName}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {label}
          </Typography>
          {(imagePath || formField.input.value) && <div className={classes.preview}>
            <img key={imagePath || formField.input.value} src={imagePath || formField.input.value} style={{
              maxWidth: '100%',
              marginBottom: 16
            }} />
            <ClearIcon
                style={{
                  color: red[500],
                  cursor: isLoading ? 'not-allowed' : 'pointer'
                }}
                onClick={_deleteFile}
              />
          </div>}
          <div className={classes.actionZone}>
            <section className={classes.container}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <Typography color="textSecondary">
                Drag and drop, or  click to upload image.
                </Typography>
              </div>
            </section>
          </div>
          <Button
            type='button'
            variant='contained'
            label={isSuccess ? 'Uploaded' : 'Upload'}
            disabled={!image || isLoading || isSuccess}
            startIcon={isLoading
              ? <CircularProgress size="1rem" />
              : isSuccess
                ? <CheckCircleOutlinedIcon fontSize='default' />
                : null
            }
            onClick={uploadImage}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export const SignedUrlFileUploadInput = connect(null, {
  showNotification: showNotification,
})(_SignedUrlFileUploadInput)
