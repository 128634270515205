import { Button, CardActions, useMediaQuery } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MailIcon from '@material-ui/icons/Mail'
import { Record, useNotify, useRedirect, useRefresh } from 'ra-core'
import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { EditButton, useGetIdentity, usePermissions } from 'react-admin'
import urlJoin from 'url-join'
import { LogVaultFreezeReasonsAndUpdateVaultStatusInput } from '../__generated__/flexgold/globalTypes'
import { BrokerAgentUploadForm } from '../apps/brokerSetupWizard/BrokerAgentUploadForm'
import { OnTablePermissions, Permissions } from '../authProvider'
import { authedFetch } from '../dataProvider/authedFetch'
import { smplColors } from '../layout/themes'
import {
  EXTERNAL_API_ORDER_ENDPOINT,
  SMPL_DATA_API_URL,
  SMPL_USER_AUTH_API_URL,
  TABLE_ENTRY_DELETE_RESTRICTION_ROLES,
  isFlexgoldCustomer,
} from '../lib/config'
import { CustomDeleteButton } from '../utils/CustomDeleteButton'
import { CustomDeleteWithConfirmButton } from '../utils/CustomDeleteWithConfirmButton'
import { MovieAction } from './customActions/MovieAction'
import { ScheduledTaskAction } from './customActions/ScheduledTaskAction'
import CreatePowerOfAttorneyForm from './customForm/CreatePowerOfAttorneyForm'
import CreateTransferOrderForm from './customForm/CreateTransferOrderForm'
import { VaultFreezeForm, VaultUnfreeze } from './customForm/VaultFreezeForm'
import { NodeActions, SplitNodeActions } from './node-actions'
import { createSimpleAuthenticatedClient } from './node-actions/user/lib/simpleClient'
import SplitActionButton from './SplitActionButton'

const noop = () => {}

const toggleVaultFreezeMutation = `
mutation toggleFreeze(
  $input: LogVaultFreezeReasonsAndUpdateVaultStatusInput!
) {
  logVaultFreezeReasonsAndUpdateVaultStatus(input: $input) {
    clientMutationId

  }
}`

type Props = {
  basePath: string
  data: Record
  resource: string
}

export type CustomActionProps = {
  classes: any
  data: Record
  resource: string
}

const useStyles = makeStyles((theme: Theme) => ({
  monoAction: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 2,
  },
  action: {
    display: 'flex',
  },
  leftAction: {
    width: '30%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 2,
  },
  middleAction: {
    width: '40%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2,
  },
  rightAction: {
    width: '30%',
    position: 'relative',
    display: 'flex',
    gap: '0.25rem',
    justifyContent: 'flex-end',
    zIndex: 2,
  },
  statusButton: {
    cursor: 'default',
    '&:hover': {
      cursor: 'default',
    },
  },
  uploadBtn: {
    color: smplColors.gray[100],
  },
  uploadConfirmBtn: {
    color: smplColors.primary.main,
  },
}))

// INFO: this will be shown inside the warning box + ' Are you sure you want to delete this?'
const warningMessageOnResource: {
  [fieldName in string]: string
} = {
  CmsCategory:
    'If this category has subcategories, they will not be deleted. This category will also be removed from assigned movies and series.',
  CmsEpisode: 'All related watchlist items will also be deleted.',
  CmsMovie:
    'All related trailers, watchlist and wishlist items will also be deleted.',
  CmsSeason: 'All related episodes will also be deleted.',
  CmsSery:
    'All related seasons, episodes, trailers and wishlist items will also be deleted.',
  VoucherCampaign: 'All related voucher codes will also be deleted.',
  CmsFeedObject:
    "It is not recommended to remove this entry. If you want to take this offline, it is much more advisable to set the associated license's forceOffline to true.",
  CmsLicense:
    "It is not recommended to remove this entry. If you want to take this offline, it is much more advisable to set the license's forceOffline to true. ",
}

function GenericTypeActionsImpl({ basePath, data, resource }: Props) {
  const classes = useStyles()
  const redirect = useRedirect()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const [resPermissions, setResPermissions] =
    useState<OnTablePermissions>(undefined)

  const [deleteAllowed, setDeleteAllowed] = useState<boolean>(true)

  const { permissions, loaded: permissionLoaded } =
    usePermissions<Permissions>()
  const { identity, loaded: identityLoaded } = useGetIdentity()

  useEffect(() => {
    if (permissions) {
      setResPermissions(permissions.tablePermissions[resource])
    }
  }, [permissionLoaded])

  useEffect(() => {
    if (identity && data) {
      const deleteRestriction = (
        JSON.parse(TABLE_ENTRY_DELETE_RESTRICTION_ROLES) as [
          {
            tableName: string
            restrictedRoles: string[]
            restrictions: { [fieldName in string]: string }
          }
        ]
      ).find(
        (restriction) =>
          restriction.tableName === resource &&
          restriction.restrictedRoles.includes(identity.role)
      )
      if (deleteRestriction) {
        for (const field in deleteRestriction.restrictions) {
          const retrictedValue = deleteRestriction.restrictions[field]
          if (data.hasOwnProperty(field) && data[field] !== retrictedValue) {
            setDeleteAllowed(false)
          }
        }
      }
    }
  }, [identityLoaded, data])

  const refresh = useRefresh()
  const notify = useNotify()
  const [isFrozen, setIsFrozen] = useState(false)
  const [showFreezeModal, setShowFreezeModal] = useState<true>(undefined)
  const [freezeReasons, setFreezeReasons] = useState<string[]>(undefined)
  useEffect(() => {
    setFreezeReasons(data?.freezeReasons)
  }, [data?.freezeReasons])

  const actions = useMemo(() => {
    const actions = NodeActions[resource] || []

    return actions.map((Component, index) => {
      return (
        <Component
          key={Component.label || index}
          record={data}
          resource={resource}
        />
      )
    })
  }, [resource, data])

  useEffect(() => {
    setIsFrozen(data?.status === 'Frozen')
  }, [data])

  if (!data) {
    return null
  }

  const confirmContent = warningMessageOnResource[resource]

  const handleFreezeConfirm = async (reasons: string[] | null) => {
    if (isFrozen === !!reasons) {
      return
    }
    if (reasons?.length === 0) {
      setShowFreezeModal(true)
      notify(
        'You need to choose at least 1 reason to freeze the vault.',
        'error'
      )
      return
    }
    setShowFreezeModal(undefined)

    const url = SMPL_DATA_API_URL
    const serviceOrderUrl = urlJoin(
      EXTERNAL_API_ORDER_ENDPOINT,
      `/vault/status/${data.id}`
    )
    try {
      // these values are coming from the service-orders src/commons/common.types.ts: VaultStatusEnum - DON'T CHANGE THEM
      const bodyStatus = isFrozen ? 'Active' : 'Frozen'
      await authedFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: toggleVaultFreezeMutation,
          operation: 'toggleFreeze',
          variables: {
            input: {
              reasons: reasons,
              vaultId: data.id.toString(),
            } satisfies LogVaultFreezeReasonsAndUpdateVaultStatusInput,
          },
        }),
      }).then((response) =>
        response.json().then((data) => {
          if (Object.hasOwn(data, 'error') || Object.hasOwn(data, 'errors')) {
            notify(`Failed to ${isFrozen ? 'unfreeze' : 'freeze'} vault.`)
            refresh()
            return
          }
          // sync with BC
          authedFetch(serviceOrderUrl, {
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: bodyStatus }),
          })
          notify('Vault Status Changed')
          refresh()
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleSendWelcomeMail = async () => {
    console.log('handleSendWelcomeMail')
    const authClient = createSimpleAuthenticatedClient(SMPL_USER_AUTH_API_URL)
    try {
      await authClient('POST', '/auth/email-welcome', {
        id: data.id,
        email: data.email,
        username: data.username,
        name: data.name,
        role: data.role,
        givenName: data.givenName,
        familyName: data.familyName,
      })
      notify('Welcome Mail Sent')
    } catch (error) {
      notify('Nothing sent, something went wrong.')
      console.error('No WelcomeMail sent', error)
    }
  }

  const BackToRessourceButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={(event) => {
          redirect('list', basePath)
        }}
      >
        {isSmall ? 'List' : 'Back To List'}
      </Button>
    )
  }

  return (
    <div className={classes.action}>
      <CardActions className={classes.leftAction}>
        <BackToRessourceButton />
      </CardActions>
      <CardActions className={classes.middleAction}>
        {resource === 'CmsMovie' ||
        resource === 'CmsEpisode' ||
        resource === 'CmsSery' ||
        resource === 'CmsSeason' ? (
          <MovieAction classes={classes} data={data} resource={resource} />
        ) : null}
        {resource === 'ScheduledTask' ? (
          <ScheduledTaskAction
            classes={classes}
            data={data}
            resource={resource}
          />
        ) : null}
        {actions}
        {SplitNodeActions[resource] && (
          <SplitActionButton
            refresh={refresh}
            notify={notify}
            data={data}
            resource={resource}
            optionFn={SplitNodeActions[resource]}
            confirmClasses={classes.uploadConfirmBtn}
          />
        )}
      </CardActions>
      <CardActions className={classes.rightAction}>
        {isFlexgoldCustomer ? (
          <>
            {resource === 'Vault' ? (
              <CustomDeleteWithConfirmButton
                btnClassName=""
                forceOpen={showFreezeModal}
                record={data}
                confirmTitle={
                  isFrozen
                    ? 'Are all of these freeze reasons resolved?'
                    : 'Freeze Vault'
                }
                confirmContent={
                  isFrozen ? (
                    <VaultUnfreeze freezeReasons={freezeReasons} />
                  ) : (
                    <VaultFreezeForm
                      freezeReasons={freezeReasons}
                      setFreezeReasons={setFreezeReasons}
                    />
                  )
                }
                confirm={isFrozen ? 'Unfreeze' : 'Freeze Vault'}
                label={isFrozen ? 'Unfreeze' : 'Freeze'}
                color="primary"
                size="medium"
                icon={<AcUnitIcon />}
                confirmClasses={classes.uploadConfirmBtn}
                onConfirm={() =>
                  handleFreezeConfirm(isFrozen ? null : freezeReasons)
                }
              />
            ) : null}
            {identityLoaded &&
            identity?.role === 'admin' &&
            resource === 'Vault' &&
            data?.type === 'broker' ? (
              <CustomDeleteWithConfirmButton
                btnClassName=""
                record={data}
                disableCancelBtn
                confirmTitle="Bulk create agents"
                confirmContent={
                  <BrokerAgentUploadForm brokerVaultId={data.id} noPadding />
                }
                confirm="Close"
                label="Add agents"
                color="primary"
                size="medium"
                icon={<AddToPhotosIcon />}
                confirmClasses={classes.uploadConfirmBtn}
                onConfirm={noop}
              />
            ) : null}
            {resource === 'User' && data?.role === 'broker' ? (
              <Button
                color="primary"
                size="medium"
                onClick={handleSendWelcomeMail}
                startIcon={<MailIcon />}
              >
                Send Welcome
              </Button>
            ) : null}
              {resource === 'IdentificationDatum' ? (
              <>
                <CustomDeleteWithConfirmButton
                  btnClassName=""
                  record={data}
                  confirmTitle="Power of Attorney"
                  confirmContent={<CreatePowerOfAttorneyForm data={data} />}
                  confirm="Done"
                  label="PoA"
                  color="primary"
                  size="medium"
                  disableCancelBtn={true}
                  icon={<AccountBalanceIcon />}
                  confirmClasses={classes.uploadConfirmBtn}
                  onConfirm={() => refresh(true)}
                />
              </>
            ) : null}
            {resource === 'Vault' ? (
              <>
                <CustomDeleteWithConfirmButton
                  btnClassName=""
                  record={data}
                  confirmTitle="Transfer"
                  confirmContent={<CreateTransferOrderForm data={data} />}
                  confirm="Done"
                  label="Transfer"
                  color="primary"
                  size="medium"
                  disableCancelBtn={true}
                  icon={<AccountBalanceIcon />}
                  confirmClasses={classes.uploadConfirmBtn}
                  onConfirm={() => refresh(true)}
                />
                <CustomDeleteWithConfirmButton
                  btnClassName=""
                  record={data}
                  confirmTitle="Power of Attorney"
                  confirmContent={<CreatePowerOfAttorneyForm data={data} />}
                  confirm="Done"
                  label="PoA"
                  color="primary"
                  size="medium"
                  disableCancelBtn={true}
                  icon={<AccountBalanceIcon />}
                  confirmClasses={classes.uploadConfirmBtn}
                  onConfirm={() => refresh(true)}
                />{' '}
              </>
            ) : null}
          </>
        ) : null}
        {/* CUSTOMER === 'flexgold' END */}

        {resPermissions && resPermissions.canUpdate ? (
          <EditButton resource={resource} basePath={basePath} record={data} />
        ) : undefined}

        {resPermissions && resPermissions.canDelete && deleteAllowed ? (
          <CustomDeleteButton
            resource={resource}
            basePath={basePath}
            record={data}
            undoable={false}
            confirmContent={
              confirmContent
                ? confirmContent + ' Are you sure you want to delete this?'
                : 'Are you sure you want to delete this?'
            }
          />
        ) : undefined}
      </CardActions>
    </div>
  )
}

// @ts-ignore
export const GenericTypeActions: ComponentType<{}> = React.memo(
  GenericTypeActionsImpl
)
