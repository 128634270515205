import gql from 'graphql-tag'

export const grantPowerOfAttorneyMutation = gql`
  mutation GrantPowerOfAttorney(
    $entityId: String!
    $userIdToBeGrantedTo: String!
    $actionToTake: String!
    $grantType: String!
  ) {
    grantPowerOfAttorney(
      input: {
        entityId: $entityId
        userIdToBeGrantedTo: $userIdToBeGrantedTo
        actionToTake: $actionToTake
        grantType: $grantType
      }
    ) {
      string
    }
  }
`
