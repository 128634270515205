import {
  Button,
  Container,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { Record, useNotify } from 'react-admin'
import { useMutation } from 'react-apollo-hooks'
import {
  GrantPowerOfAttorney,
  GrantPowerOfAttorneyVariables,
} from '../../__generated__/flexgold/GrantPowerOfAttorney'
import { grantPowerOfAttorneyMutation } from '../../graphQL/flexgold/powerOfAttorney'

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
}))

export type CreatePowerOfAttorneyFormProps = {
  data: Record
}

const CreatePowerOfAttorneyForm = ({
  data,
}: CreatePowerOfAttorneyFormProps) => {
  const classes = useStyles()
  const notify = useNotify()
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)

  const executeGrantPowerOfAttorneyMutation = useMutation<
    GrantPowerOfAttorney,
    GrantPowerOfAttorneyVariables
  >(grantPowerOfAttorneyMutation)

  const handlePowerOfAttorneyAction = async (
    vaultId: string,
    action: 'grant' | 'revoke'
  ) => {
    if (!userId) {
      notify('Please enter a User ID')
      return
    }

    setLoading(true)
    try {
      const res = await executeGrantPowerOfAttorneyMutation({
        variables: {
          entityId:
            data.__typename === 'IdentificationDatum'
              ? data.id.toString()
              : vaultId,
          userIdToBeGrantedTo: userId,
          actionToTake: action,
          grantType:
            data.__typename === 'IdentificationDatum'
              ? 'poa_identification_data'
              : 'poa_vault',
        },
      })

      if (res?.data?.grantPowerOfAttorney?.string) {
        notify(
          `Successfully ${
            action === 'grant' ? 'granted' : 'revoked'
          } Power of Attorney`
        )
        setUserId('')
      } else {
        notify(`Failed to ${action} Power of Attorney`)
      }
    } catch (error) {
      notify(error.message || 'An error occurred')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Paper>
        <Container className={classes.formContainer}>
          <TextField
            fullWidth
            variant="outlined"
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            disabled={loading}
          />

          <div className={classes.buttonGroup}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                handlePowerOfAttorneyAction(data.id as string, 'grant')
              }
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Grant'}
            </Button>

            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                handlePowerOfAttorneyAction(data.id as string, 'revoke')
              }
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Revoke'}
            </Button>
          </div>
        </Container>
      </Paper>
    </Container>
  )
}

export default CreatePowerOfAttorneyForm
