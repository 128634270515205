import { Collapse } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { createMuiTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { FormApi } from 'final-form'
import { motion } from 'framer-motion'
import { Location } from 'history'
import PropTypes from 'prop-types'
import { Notification as RANotification } from 'ra-ui-materialui'
import { useEffect, useRef, useState } from 'react'
import { useLogin, useNotify, useTranslate } from 'react-admin'
import { Field, withTypes } from 'react-final-form'
import { useSelector, useStore } from 'react-redux'
import LoginBackground from '../assets/SMPL_02_dark_spots.jpg'
import authProvider, { MFAError, MFAErrorFailure } from '../authProvider'
import { CUSTOMER } from '../lib/config'
import { getAfterLoginRedirect } from '../utils/afterLoginURL'
import { hideSplashScreen } from '../utils/splashScreen'
import { ARDLogo } from './customerThemes/ard'
import { FilmhomeLogo } from './customerThemes/filmhome'
import { FlexgoldLogo } from './customerThemes/flexgold'
import { KixiLogo } from './customerThemes/kixi'
import { NetzkinoLogo } from './customerThemes/netzkino'
import { StandardLogo } from './customerThemes/standard'
import { UniversumLogo } from './customerThemes/universum'
import { lightTheme, smplColors } from './themes'

const useStyles = makeStyles((theme) => ({
  '@keyframes backDropBlurOut': {
    from: {
      backdropFilter: 'blur(15px)',
    },
    to: {
      backdropFilter: 'blur(0px)',
    },
  },
  main: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: `url(${LoginBackground}) black bottom center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: `${smplColors.primary.main}90`,
    backdropFilter: 'blur(0px)',
    animation: '$backDropBlurOut 3.5s ease',
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '40px',
    background: '#232323',
    lineHeight: '40px',
    textAlign: 'center',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '10px',
    color: '#999999',
    letterSpacing: '2px',

    '& span': {
      color: 'white',
    },
  },
  logo: {
    position: 'absolute',
    top: '40px',
    paddingRight: '20px',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
    borderRadius: '1rem',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: smplColors.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}))

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...input },
  ...props
}) => {
  return (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...input}
      {...props}
      fullWidth
    />
  )
}

interface FormValues {
  username?: string
  password?: string
  otp?: string
}

const { Form } = withTypes<FormValues>()

const Login = ({ location }: { location: Location }) => {
  const [loading, setLoading] = useState(false)

  // some customers require verification through 2FA tokens
  const [mfaRequired, setMFARequired] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const login = useLogin()
  const store = useStore()
  const apps = useSelector((state: any) => state.apps)
  const formRef = useRef<typeof Form>()

  const gLogo = useRef() as any
  const gLogin = useRef() as any
  const gFooter = useRef() as any
  useEffect(() => {
    authProvider
      .checkAuth({})
      .then(() => {
        const target = getAfterLoginRedirect()
        const href = target?.includes('/login') ? '/' : target

        console.log('already logged in, redirecting to', href)
        window.location.href = href
      })
      .catch(() => {
        // do nothing
      })
  }, [])

  useEffect(() => {
    // if login page is shown, the app might not be able to trigger the splashscreen hiding
    hideSplashScreen()
  }, [])

  const handleSubmit = (auth: FormValues, form: FormApi<FormValues>) => {
    setLoading(true)

    form.change('otp', undefined)

    const wasMFARequired = mfaRequired

    login(auth, location.state ? location.pathname : '/')
      .then((res) => {
        // LOGIN success do:
        // RA no konger dispatch RA/USER_LOGIN_SUCCESS
        // manually dispatch something to fetch apps
        console.log('fetching apps after login')
        store.dispatch({ type: 'SMPL/LOGGED_IN' })
        // interestingly there is actually a case where the nextPathname on
        // the login page is /login which lead to endless loading
        // manually redirect to main page in such cases:
        if (location.state && location.pathname === '/login') {
          window.location.href = getAfterLoginRedirect()
        }
      })
      .catch((error: Error | typeof MFAError | typeof MFAErrorFailure) => {
        if (error == MFAErrorFailure) {
          form.change('otp', '')
          notify(
            'Invalid Login Token. Please check your mails for a login token',
            'error'
          )
          return
        } else if (error === MFAError) {
          setMFARequired(true)
          notify('Please check your mails for a login token', 'info')
          return
        }
        setMFARequired(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const validate = (values: FormValues) => {
    const errors: FormValues = {}
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.main}>
            <div className={classes.overlay}>
              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                whileHover={{ scale: 1.2 }}
                transition={{ ease: 'backOut', duration: 3 }}
                className={classes.logo}
              >
                {CUSTOMER === 'ard'
                  ? ARDLogo
                  : CUSTOMER === 'kixi'
                  ? KixiLogo
                  : CUSTOMER === 'netzkino'
                  ? NetzkinoLogo
                  : CUSTOMER === 'universum'
                  ? UniversumLogo
                  : CUSTOMER === 'filmhome'
                  ? FilmhomeLogo
                  : CUSTOMER === 'flexgold'
                  ? FlexgoldLogo
                  : StandardLogo}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 250 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ ease: 'anticipate', duration: 2 }}
              >
                <Card className={classes.card}>
                  <div className={classes.avatar}>
                    {/* <Avatar className={classes.icon}>
                    <LockIcon />
                  </Avatar> */}
                  </div>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        autoFocus
                        name="username"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('ra.auth.username')}
                        disabled={loading || mfaRequired}
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        name="password"
                        // @ts-ignore
                        component={renderInput}
                        label={translate('ra.auth.password')}
                        type="password"
                        disabled={loading || mfaRequired}
                      />
                    </div>
                    <Collapse in={mfaRequired}>
                      {mfaRequired ? (
                        <div className={classes.input}>
                          <Field
                            name="otp"
                            // @ts-ignore
                            component={renderInput}
                            label={translate('E-Mail Token')}
                            autoFocus
                            disabled={loading}
                            inputProps={{
                              autocomplete: 'one-time-code',
                            }}
                          />
                        </div>
                      ) : null}
                    </Collapse>
                  </div>
                  <CardActions className={classes.actions}>
                    <Button
                      variant="text"
                      type="submit"
                      color="primary"
                      disabled={loading}
                      fullWidth
                      style={{
                        boxShadow: '0.15rem 0.15rem 0.2rem #00000050',
                        background: `${smplColors.secondary.main}`,
                        color: 'white',
                      }}
                    >
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={25}
                          thickness={2}
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </CardActions>
                </Card>
              </motion.div>
              <RANotification />
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ duration: 1 }}
                className={classes.footer}
              >
                Made with <span>♥</span> by simpleTechs GmbH
              </motion.div>
            </div>
          </div>
        </form>
      )}
    />
  )
}

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
)

export default LoginWithTheme
